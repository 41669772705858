import '../App.css';
import React, {useEffect, useState} from "react";
import base from '../img/base.jpg'

const width = 1500
const height = 2000
const x = 745;
const maxSize = 100;
const sizeVaribale = 1800;
const komoji = ["ぁ", "ぃ", "ぅ", "ぇ", "ぉ", "っ", "ゃ", "ゅ", "ょ", "ゎ", "ゕ", "ゖ",
    "ァ", "ィ", "ゥ", "ェ", "ォ", "ヵ", "ㇰ", "ヶ", "ㇱ", "ㇲ", "ッ", "ㇳ", "ㇴ", "ㇵ", "ㇶ", "ㇷ", "ㇷ",
    "゚", "ㇸ", "ㇹ", "ㇺ", "ャ", "ュ", "ョ", "ㇻ", "ㇼ", "ㇽ", "ㇾ", "ㇿ", "ヮ"]
const kutouten = ["、", "。", "．", "，"]
const kigouYoko = ["-", "~", "～", "〜", "[", "]", "(", ")", "{", "}", "｛", "｝", "「", "」", "…", "【", "】", "『", "』", "（", "）", "＝", "：", "；"]
const defaultTanka = "はなのいろは　うつりにけりな　いたづらに　わが身世にふる　ながめせしまに"

function Gen() {
    const [png, setPng] = useState()
    const [TweetText, setTweetText] = useState("https://twitter.com/intent/tweet?text=はなのいろは　うつりにけりな　いたづらに　わが身世にふる　ながめせしまに%0A&hashtags=短歌,たてがき");
    const setSize = (length) => {
        let size = sizeVaribale / length
        if (size > maxSize) {
            size = maxSize
        }
        return size
    }
    const matchEnglish=(input)=>{
        let match = input.match(/[A-Za-z0-9]+/g);
        if(match !== null){
            return{
                "match":match,
                "quantity":match.length,
            }
        }else{
            return{
                "match":null,
                "quantity":0
            }
        }
    }

    const setTop = (size, length,input) => {
        if (length > 1) {
            let m = matchEnglish(input)
            let minus = 0
            for(let index=0; index < m.quantity; index++){
                minus += m.match[index].length / 3.4
            }
            let len = length - minus
            return 1000 - (size * len / 2) + size / 1.5
        } else {
            return 1000
        }
        // return 1000+size/2
    }
    const nowTime = ( )=> {
        let date = new Date()
        return date.getFullYear().toString() +
        date.getMonth().toString() +
        date.getDay().toString() +
        date.getHours().toString() +
        date.getMinutes().toString() +
        date.getSeconds().toString()
    }
    const updatePic = () => {
        const font = new FontFace('soukou', 'url(/SoukouMincho.woff)')
        const canvasElem = document.getElementById('canvas-area')
        canvasElem.width = width
        canvasElem.height = height
        const ctx = canvasElem.getContext('2d')
        let input = document.getElementById("input-text-area").value ? document.getElementById("input-text-area").value : defaultTanka
        setTweetText("https://twitter.com/intent/tweet?text=" + input + "%0A&hashtags=短歌,たてがき")
        let length = input.length
        let size = setSize(length)
        ctx.font = size + "px soukou"
        let top = setTop(size, length,input)

        let hps = x - size * 0.5
        let vps_base = top
        let vps = vps_base
        let chr = ''
        let fontsize = size; // フォントサイズ
        let c = 0;
        font.load().then(() => {
            const backimage = new Image()
            backimage.src = base
            // draw
            backimage.onload = () => {
                ctx.drawImage(backimage, 0, 0, width, backimage.height * width / backimage.width);
                for (let i = 0; i < length; i++) {
                    chr = input.charAt(i);
                    vps = vps_base + (fontsize * c); // 位置
                    ctx.save()
                    let Bhps = hps
                    let Bvps = vps
                    switch (true) {
                        case kutouten.includes(chr):
                            hps = hps + (fontsize * 0.7);
                            vps = vps - (fontsize * 0.7);
                            break;
                        case komoji.includes(chr):
                            hps = hps + (fontsize * 0.1);
                            vps = vps - (fontsize * 0.1);
                            break;
                        case kigouYoko.includes(chr):
                            ctx.translate(hps, vps);
                            ctx.rotate(90 * Math.PI / 180);
                            vps = -(fontsize * 0.1);
                            hps = -(fontsize * 0.8);
                            break;
                        case ["ー"].includes(chr):
                            ctx.translate(hps, vps);
                            ctx.transform(1,0,0,-1,0,0)
                            ctx.rotate(-87 * Math.PI / 180);
                            vps = +(fontsize * 0.87);
                            hps = -(fontsize * 0.85);
                            break;
                        case /[〝]/.test(chr):
                            hps = hps - (fontsize * 0.5);
                            vps = vps + (fontsize * 0.5);
                            break;
                        case /[〟]/.test(chr):
                            hps = hps + (fontsize * 0.5);
                            vps = vps - (fontsize * 0.5);
                            break;
                        default:
                            break;
                    }
                    let difH = hps - Bhps;
                    let difV = Bvps - vps;

                    if (chr.match(/[A-Za-z0-9.!?,]/)) { // 半角文字の回転処理
                        let w = ctx.measureText(chr).width;
                        let p = w / fontsize;
                        ctx.save();
                        ctx.setTransform(1, 0, 0, 1, 0, 0);
                        ctx.rotate(90 * Math.PI / 180);
                        ctx.fillText(chr, vps - fontsize*0.75, -hps - fontsize * 0.25);
                        ctx.restore();
                        c = c + p * 1.2;
                    } else {
                        ctx.fillText(chr, hps, vps);
                        c++;
                    }
                    hps = hps - difH;
                    vps = vps + difV;
                    ctx.restore();
                }
                setPng(canvasElem.toDataURL())
            }
        })
    }
    useEffect(() => {
        updatePic()
    })
    return (
        <div>
            <canvas id={"canvas-area"} style={{display: "none"}}/>
            {png && (
                <figure>
                    <img alt={"Tategaki_"+nowTime()} src={png} id={"genImage"} style={{margin: "auto"}}/>
                </figure>
            )}
            <div className={"genArea"}>
            <textarea name={"text"} id={"input-text-area"} className={"input-text"} maxLength={45}
                      defaultValue={""}
                      onChange={updatePic} placeholder={"短歌を入力"}
            >
            </textarea>
                <button className={"savePic-button"} onClick={() => {
                    let canvas = document.getElementById("canvas-area")
                    let link = document.createElement("a")

                    link.href = canvas.toDataURL("image/png")
                    link.download = "Tategaki_" + nowTime() +
                        ".png"
                    link.click()
                }}>
                    画像を保存する
                </button>
            </div>
            <p className={"cautionSmart"}>スマホは画像を長押しで保存</p>
            <div className={"tweet"}>
                <a href={TweetText} target={"_blank"} rel={"noreferrer"}>
                    <div className={"twitter-button"}>
                        <div>
                            <img src={"/twitter.svg"} alt={"twitter"} className={"twitter-img"}/>
                        </div>
                        <p>つぶやく</p>
                    </div>
                </a>
                <p className={"tweet-text"}>画像と一緒にツイートしてみてください</p>
            </div>
        </div>
    );
}

export default Gen;
