import './App.css';
import Gen from "./components/Gen";

function App() {
    return (
        <section className={"main px"}>
            <Gen></Gen>
            <footer>
                <h1>たてがき</h1>
                <p>
                    短歌を入力するだけで誰でもすぐ<br></br>
                    短歌画像を作ることができます。
                </p>
                <div className={"director"}>
                    <h2>企画</h2>
                    <h3>武田ひか</h3>
                    <a href={"https://cotanka.com/"} rel={"noreferrer"} target={"_blank"}>
                        <p>ホームページ</p>
                    </a>
                    <a href={"https://twitter.com/sunamerinikki"} rel={"noreferrer"} target={"_blank"}>
                    <p>Twitter</p>
                    </a>
                </div>
                <div className={"director"}>
                    <h2>制作</h2>
                    <h3>今田将也</h3>
                    <a href={"https://r-e-s.me/05msy25"} rel={"noreferrer"} target={"_blank"}>
                        <p>ホームページ</p>
                    </a>
                    <a href={"https://twitter.com/05msy25"} rel={"noreferrer"} target={"_blank"}>
                        <p>Twitter</p>
                    </a>
                </div>
          </footer>
      </section>
  );
}

export default App;
